<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="名称" />
            </a-form-model-item>
            <a-form-model-item>
              <a-select
                placeholder="分类"
                v-model="form.type"
                style="width: 200px"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <a-button @click="$router.push($route.path + '/add')"
                >创建
              </a-button>
              <Uploader @refresh="getList" />
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="背景图" align="center">
          <template slot-scope="text">
            <a target="_blank" :href="text.modelImg">
              <img
                style="width: 48px; border-radius: 2px"
                :src="text.modelImg"
                alt=""
              />
            </a>
          </template>
        </a-table-column>
        <a-table-column title="计算书名称">
          <template slot-scope="text">
            <a href="#" @click.prevent="goDetail(text)">{{ text.name }}</a>
          </template>
        </a-table-column>

        <a-table-column title="分类">
          <template slot-scope="text">
            <DataDictFinder
              dictType="bim_calc_book_type"
              :dictValue="text.type"
            />
          </template>
        </a-table-column>

        <a-table-column title="备注" data-index="remark"></a-table-column>

        <a-table-column title="操作" align="center" width="200px">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent.stop="goDetail(text)">查看</a>
              <a href="#" @click.prevent.stop="edit(text)">编辑</a>
              <a href="#" class="danger" @click.prevent.stop="remove(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { mapGetters } from "vuex";
import { fetchList, remove } from "@/api/parameterization/book";
import Uploader from "./components/uploader.vue";

export default {
  mixins: [watermark],

  components: {
    Uploader,
  },

  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("bim_calc_book_type");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    edit(item) {
      this.$router.push(this.$route.path + "/edit?id=" + item.id);
    },
    remove(item) {
      const that = this;
      this.$confirm({
        title: "是否删除？",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          remove({ id: item.id }).then(() => {
            that.getList();
          });
        },
      });
    },
    goDetail(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    },
  },
};
</script>
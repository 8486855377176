<template>
  <span>
    <input ref="uploader" id="uploader" type="file" @change="onChange" />
    <a-button @click="onClick" :loading="loading" type="primary"
      >导入
    </a-button>
  </span>
</template>

<script>
import { importFile } from "@/api/parameterization/book";
export default {
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.uploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("jsonFile", files[i]);
      }

      this.loading = true;

      importFile(data)
        .then(() => {
          this.$emit("refresh");
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#uploader {
  display: none;
}
</style>